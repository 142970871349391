<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="drivers"
      class="elevation-1"
      item-key="id"
    >
    <template v-slot:[`item.actions`]="{item}">
      <v-icon @click="deleteDriver(item._id)">mdi-delete</v-icon>
    </template>
    </v-data-table>
    <v-row no-gutters justify="center">
            <div>
              <v-btn @click="dialog = true" color="primary">
                <h1>+</h1>
              </v-btn>
            </div>
          </v-row>
       <driver-modal
        v-if="dialog"
        :dialog="dialog"
        @insert-driver="insertDriver"
        @close-dialog="dialog=false">
       </driver-modal>    
  </v-container>
</template>

<script>
    import driverModal from "../components/driverModal"
import { errorHandler, fetchGET, fetchPOST, successHandler } from '../js/functions'
export default {
  name: "drivers",
  data() {
    return {
        dialog: false,
        drivers: [],
      headers: [
      { text: "Naam", value: "name" },
      { text: "", value: "actions",align: "end",sortable:false },
     ]
    }
  },
  methods: {
      async insertDriver(driver){
        try { 
          this.dialog= false
          let response = await fetchPOST("insertDriver",driver)          
          if(response.insertedCount===0) throw "insertDriver"
          this.drivers.push(response.ops[0])
          successHandler("Chauffeur opgeslagen")
        } catch (e) {
          errorHandler(e,"Chauffeur niet opgeslagen")
        }        
      },
    async deleteDriver(_id){
        try { 
          let response = await fetchPOST("deleteDriver",{_id:_id})
          if(response.deletedCount===0) throw "deleteDriver"
          let index = this.drivers.findIndex(driver=> driver._id ===_id)
          if(index>-1)this.drivers.splice(index,1)
          successHandler("Chauffeur verwijderd")
        } catch (e) {
          errorHandler(e,"Chauffeur niet verwijderd")
        }        
      }
  },
  computed: {

  },
  async mounted() {
    this.drivers = await fetchGET("fetchDrivers",{})
  },
  components: {driverModal}
}
</script>

<style>
</style>