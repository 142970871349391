<template>
      <v-dialog :value="dialog" persistent hide-overlay width="500px">
              <v-card>
             <v-text-field
                 label="Naam"
                 v-model="driver.name"
             ></v-text-field>
             <v-btn color="primary" @click="insertDriver">Opslaan</v-btn>
             <v-btn color="primary" @click="closeDialog">Annuleer</v-btn>
              </v-card>
          </v-dialog>
</template>

<script>
export default {
    name:"driverModal",
    props: {dialog: Boolean},
    data(){
        return{
            driver:{
                name: " ",
                }
        }
    },
    methods:{
insertDriver(){
    this.$emit("insert-driver",this.driver)
},
closeDialog(){
    this.$emit("close-dialog")
}
    },
computed:{
   
}
}
</script>

<style>

</style>